const Device = {    
    isTouch: function () {
        return (('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            (navigator.msMaxTouchPoints > 0));
    },
    isSmallScreen() {
        return window.innerWidth <= App.mobileBreak
    },
    isMobile: function () {
        return this.isSmallScreen() && this.isTouch()
    },
    name: "",
    os: "",
    device: "",
    getBrowserName: function () {
        var name = "unknown-browser";
        if(navigator.userAgent.indexOf("MSIE")!=-1 || navigator.userAgent.indexOf("rv:11.0")!=-1) name = "msie";
        else if(navigator.userAgent.indexOf("Edge")!=-1) name = "microsoft-edge";
        else if(navigator.userAgent.indexOf("Firefox")!=-1) name = "firefox";
        else if(navigator.userAgent.indexOf("Opera")!=-1) name = "opera";
        else if(navigator.userAgent.indexOf("Chrome") != -1) name = "chrome";
        else if(navigator.userAgent.indexOf("Safari")!=-1) name = "safari";
    
        var OSName="unknown-os";
        if (navigator.appVersion.indexOf("Win")!=-1) OSName="windows";
        if (navigator.appVersion.indexOf("Mac")!=-1) OSName="mac-os";
        if (navigator.appVersion.indexOf("X11")!=-1) OSName="unix";
        if (navigator.appVersion.indexOf("Linux")!=-1) OSName="linux";
    
        var IEVersion="not-ie";
        if(navigator.userAgent.indexOf("rv:11.0")!=-1) IEVersion = "ie ie-11";
        else if(navigator.userAgent.indexOf("MSIE 10.0")!=-1) IEVersion = "ie ie-10";
        else if(navigator.userAgent.indexOf("MSIE 9.0")!=-1) IEVersion = "ie ie-9";
        else if(navigator.userAgent.indexOf("MSIE 8.0")!=-1) IEVersion = "ie ie-8";
        else if(navigator.userAgent.indexOf("MSIE 7.0")!=-1) IEVersion = "ie ie-7";
    
        var windowsVersion='not-windows';
        if(navigator.userAgent.indexOf("Windows NT 5.0")!=-1) windowsVersion = "windows-2000";
        else if (navigator.userAgent.indexOf("Windows NT 5.1")!=-1) windowsVersion="windows-xp";
        else if(navigator.userAgent.indexOf("Windows NT 6.0")!=-1) windowsVersion = "windows-vista";
        else if(navigator.userAgent.indexOf("Windows NT 6.1")!=-1) windowsVersion = "windows-7";
        else if(navigator.userAgent.indexOf("Windows NT 6.2")!=-1) windowsVersion = "windows-8";
        else if(navigator.userAgent.indexOf("Windows NT 6.3")!=-1) windowsVersion = "windows-8-1";
        else if(navigator.userAgent.indexOf("Windows NT 10.0")!=-1) windowsVersion = "windows-10";
        
        var device="not-mobile"
        if (navigator.userAgent.match(/Android|BlackBerry|Kindle|iPhone|iPad|iPod|Opera Mini|IEMobile/i)) device="is-mobile";
    
        var isKindle="not-kindle"
        if (navigator.userAgent.match(/Kindle|KFTHWI/i)) isKindle="kindle";

        this.name = name
        this.os = OSName
        this.device = device
    
        document.documentElement.classList.add(name);
        document.documentElement.classList.add(OSName);
        document.documentElement.classList.add(device);
        // $('body').addClass(IEVersion);
        // $('body').addClass(windowsVersion);
        // $('body').addClass(isKindle);
    }
}

// Device.getBrowserName()
export default Device