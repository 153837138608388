const Scroll = {
    scrollPos: 0,
    getScrollingElement: function() {
        return (document.documentElement || document.body.parentNode || document.body);
    },
    scrollTop: function() {
        return (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
    },
    disable: function() {
        this.scrollPos = this.scrollTop();
        // $('body').css('top', -this.scrollPos + 'px').addClass('disable-scroll')
        $('body').addClass('disable-scroll')
    },
    enable: function() {
        $('body').removeClass('disable-scroll')
        // console.log(this.scrollPos);
        window.scrollTo(0,this.scrollPos)
    }
}

export default Scroll