const Intro2 = function() {
    const transition = sessionStorage.getItem(App.storageItemIds.transition)
    if (!transition || transition === "false") {
        $('body').removeClass('cover')
        return
    }

    window.scrollTo(0,0);

    const tl = new gsap.timeline();
    tl.to('main', {
        y: "0",
        opacity: 1,
        duration: .5,
        ease: 'easeInBack'
    })
    tl.set('main', {clearProps: 'transform'})
    tl.add(() => {
        $('body').removeClass('cover')
        sessionStorage.setItem(App.storageItemIds.transition, false)
    })
}

export default Intro2