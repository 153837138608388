const matomoCookie = Cookies.get('kwb-tracking') 
App.consent.tracking = matomoCookie ? matomoCookie === 'confirm'  : false

const videoCookie = Cookies.get('kwb-video') 
App.consent.video = videoCookie ? videoCookie === 'confirm'  : false

console.log("cookies matomo", matomoCookie)

if (!matomoCookie) $('.dialog.cookies').addClass('visible')

$('.dialog.cookies button#dialog-reject').on('click', function() {
    console.log("reject", Cookies)

    Cookies.set('kwb-tracking', 'reject')
    Cookies.set('piwik_ignore', 'true')

    $('.dialog.cookies').removeClass('visible')
})

$('.dialog.cookies button#dialog-confirm').on('click', function() {
    console.log("confirm", Cookies)
    
    Cookies.set('kwb-tracking', 'confirm')
    Cookies.remove('piwik_ignore')
    
    if (window.initMatomo) window.initMatomo()
    $('button.switch#kwb-tracking').attr("aria-checked", "true");
    
    $('.dialog.cookies').removeClass('visible')
})