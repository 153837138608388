if (typeof createApp === 'undefined') {
	console.log('ie fallback')
	$('.header-controls .menu-toggle button').on('click', function() {
		$('nav#main-menu').addClass('show')
	})
	$('button.nav-close').on('click', function() {
		$('nav#main-menu').removeClass('show')
	})

	$('.header-controls .search button').on('click', function() {
		$('.header-search').addClass('open')
	})

	const isSearch = new RegExp(['/suche','/search'].join("|")).test(window.location.href)
	if (isSearch) $('.header-search').addClass('open')

	// click outside search
	document.addEventListener('click', (e) => {
		if (this.isSearch && !Helper.Device.isMobile()) return
		if (!this.searchOpen) return
		if ($(e.target).parents('.search').length > 0) return
		if ($(e.target).parents('.header-search').length > 0) return
		this.onCloseSearch()	
	})

	$('.nav-menu a').on('mouseenter', function(e) {
		console.log("over")
		if (Helper.Device.isMobile()) return
	
		const el = e.target
		const id = $(el).data('id')
		const img = $(this.menuBg).find('img#'+id)
		if (img) el.img = img

		if (!$(img).hasClass('current')) $(img).addClass('active')
	})

	$('.nav-menu a').on('mouseleave', function(e) {
		if (Helper.Device.isMobile()) return
	
		const el = e.target
		// const id = $(el).data('id')
		const img = el.img
		
		img.removeClass('active')
	})
} else {
	createApp({
		menuOpen: false,
		menuBg: null,
		onOpenMenu() {
			this.menuOpen = true
			setTimeout(() => {Scroll.disable()},1000) // wait for animation
		},
		onCloseMenu() {
			this.menuOpen = false
			Scroll.enable()
		},
		onMenuItemOver(e) {
			if (Helper.Device.isMobile()) return
	
			const el = e.target
			const id = $(el).data('id')
			const img = $(this.menuBg).find('img#'+id)
			if (img) el.img = img
	
			if (!img.hasClass('current')) img.addClass('active')
		},
		onMenuItemOut(e) {
			if (Helper.Device.isMobile()) return
	
			const el = e.target
			const id = $(el).data('id')
			const img = el.img
			
			img.removeClass('active')
		},
		onClick(e) {
			if (Helper.Device.isMobile()) return
			
			e.preventDefault()
			const el = e.target
			const href = e.target.href
			const img = el.img
			console.log(href)
			img.addClass('current')
			// gsap.TweenLite.to('.nav-menu', .5, {
			// 	x: '100%', 
			// 	onComplete: () => {
			// 		window.location = href
			// 	}
			// })
	
			const tl = new gsap.timeline({
				onComplete: () => {
					sessionStorage.setItem(App.storageItemIds.transition, true)
					window.location = href
				}
			})
			.set('main, footer, .page-cover', {opacity: 0}) // fade ot content
			.set('.nav-menu', {x: '100%'}) // menu animate out
			.add(() => {$('.header-logo').addClass('fixed')})
			.to('.nav-bg img.current', .5, {y: '-100%', ease: 'easeOutBack'}, "+=.5")
			.set('.nav-bg img.current', {opacity: 0}, "-=.5")
		},
	
		searchOpen: false,
		isSearch: false,
		onOpenSearch() {
			this.searchOpen = true
			$('input#q')[0].focus()
		},
		onCloseSearch() {
			this.searchOpen = false
		},
		mounted(el) {
			// this.isSearch = window.location.href.includes('/suche')
			this.isSearch = new RegExp(['/suche','/search'].join("|")).test(window.location.href)
			if (this.isSearch && !Helper.Device.isMobile()) this.searchOpen = true	
	
			// console.log(this.isSearch, !Helper.Device.isMobile(), this.isSearch && !Helper.Device.isMobile())
			// click outside search
			document.addEventListener('click', (e) => {
				if (this.isSearch && !Helper.Device.isMobile()) return
				if (!this.searchOpen) return
				if ($(e.target).parents('.search').length > 0) return
				if ($(e.target).parents('.header-search').length > 0) return
				this.onCloseSearch()
			})
	
			this.menuBg = $(el).find('.nav-bg')
		}
	}).mount('header')
}

// header load images with delay
setTimeout(function() {
	console.log("load nav bgs")
	$('.nav-bg img').each((i,e) => {
		$(e).attr('srcset', $(e).data('srcset'))
		$(e).attr('src', $(e).data('src'))
	})
},500)