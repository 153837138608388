require('intersection-observer');
require('mutationobserver-polyfill')

import 'objectFitPolyfill';
window.objectFitPolyfill();

// Number.isNaN() polyfill
if (!Number.isNaN) {
  Object.defineProperty(Number, 'isNaN', {
    value: function(value) {     
      return value !== value;
    }
  });
}