import Base64 from "./base64.js";
import Device from "./Device.js";
import StringHelper from "./StringHelper.js";
// import Deferred from 'es2015-deferred';
// import debounce from './debounce.js';

Device.getBrowserName()

const helper = {
    Base64: Base64,
    Device: Device,
    String: StringHelper
    // Deferred: Deferred,
    // debounce: debounce
}

window.Helper = helper