import Scroll from "./helper/Scroll"

window.download = ""
$('a[download]').on('click', function(e) {
    console.log('download', Helper.Device.isMobile())
    if (!Helper.Device.isMobile()) return
    
    e.preventDefault()
    
    const el = e.currentTarget
    const filename = $(el).data('filename')
    const size = $(el).data('size')

    window.download = el.href

    console.log(el, filename, size)

    let dialogBody = $('.dialog-body').text()
    if (filename) dialogBody = dialogBody.replace(/\{filename\}/, filename)
    if (size) {
        let filesizeAsMb = parseFloat(size) / 1024 / 1024
        filesizeAsMb = filesizeAsMb.toFixed(2)
        dialogBody = dialogBody.replace(/\{filesize\}/, "(" + filesizeAsMb + "MB)")
    } else {
        dialogBody = dialogBody.replace(/\{filesize\}/, "")
    }

    $('.dialog-body').html("<p>"+dialogBody+"</p>")
    $('.dialog.download').addClass('visible')

    Scroll.disable()
})

$('.dialog.download button#dialog-abort').on('click', function() {
    $('.dialog.download').removeClass('visible')
    Scroll.enable()
})

$('.dialog.download button#dialog-confirm').on('click', function() {
    console.log(download)
    window.open(download)
    download = ""
    $('.dialog.download').removeClass('visible')
    Scroll.enable()
})